import { createActionGroup, props } from '@ngrx/store';
import { EnrollmentCompat } from 'src/app/shared/models/classes/enrollment-compat';

export const EnrollmentCompatActions = createActionGroup({
  source: 'EnrollmentCompat',
  events: {
    'Load Enrollments': props<{ consumerId: string }>(),
    'Load Enrollments Success': props<{ enrollments: EnrollmentCompat[] }>(),
    'Load Enrollments Failure': props<{ error: any }>(),
    'Load Enrollment': props<{ enrollmentId: string; consumerId: string }>(),
    'Load Enrollment Success': props<{ enrollment: EnrollmentCompat }>(),
    'Load Enrollment Failure': props<{ error: any }>(),
    'Add Enrollment': props<{
      consumerId: string;
      enrollment: EnrollmentCompat | Partial<EnrollmentCompat>;
    }>(),
    'Add Enrollment Success': props<{ enrollment: EnrollmentCompat }>(),
    'Add Enrollment Failure': props<{ error: any }>(),
    'Remove Enrollment': props<{ enrollmentId: string; consumerId: string }>(),
    'Remove Enrollment Success': props<{ enrollmentId: string }>(),
    'Remove Enrollment Failure': props<{ error: any }>(),
    'Update Enrollment': props<{
      consumerId: string;
      enrollmentId: string;
      enrollment: Partial<EnrollmentCompat>;
    }>(),
    'Update Enrollment Success': props<{
      consumerId: string;
      enrollmentId: string;
      enrollment: Partial<EnrollmentCompat>;
    }>(),
    'Update Enrollment Failure': props<{ error: any }>(),
  },
});
